<script lang="ts" context="module">
  import { createEventDispatcher, onMount } from "svelte";
</script>

<script lang="ts">
  export let name: string = "";
  export let id: string;
  export let checked: boolean = false;
  export let disabled: boolean = false;
  export let value: any = undefined;
  export let size: string = "medium";
  export let autofocus = false;
  export let required = false;

  let checkboxElement: HTMLInputElement;

  const dispatch = createEventDispatcher();

  if (size === "medium") {
    size = "18px";
  } else if (size === "small") {
    size = "13px";
  }

  function onChange() {
    dispatch("change", { checked, id });
  }

  onMount(() => {
    if (autofocus) {
      checkboxElement.focus();
    }
  });
</script>

<label for={id} class="checkbox-container relative flex items-center" style="--size: {size}">
  <input
    class="mr-4"
    type="checkbox"
    bind:this={checkboxElement}
    {name}
    {id}
    bind:checked
    on:keydown
    {value}
    {disabled}
    on:change={onChange} />
  <div class="checkbox-layout checkbox-container pointer-events-none" class:error={required && !checked}>
    <span class="check-icon material-icons text-14">check</span>
  </div>
</label>

<style>
  .checkbox-container {
    width: var(--size, 18px);
    min-width: var(--size, 18px);
    height: var(--size, 18px);
  }

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: 0;
  }

  .checkbox-layout {
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    border-radius: 3px;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: #fff;
    cursor: default;
  }
  .checkbox-layout.error {
    border-color: #ad282585;
  }

  .check-icon {
    display: none;
  }

  input:focus + .checkbox-layout {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    border-color: #63b3ed;
  }
  input:checked + .checkbox-layout {
    background: #00a4bc;
    border-color: #00a4bc;
    font-size: 12px;
  }
  input:focus + .checkbox-layout.error {
    border-color: #ad2825;
    box-shadow: 0 0 0 4px #f9e7e7;
  }
  input:checked + .checkbox-layout .check-icon {
    display: block;
  }

  input:disabled + .checkbox-layout {
    pointer-events: none;
    background-color: #bbbbbb50;
    border-color: #bbbbbb;
  }
  input:disabled:checked + .checkbox-layout {
    background-color: #bbbbbb;
  }
</style>
